<template>
  <div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      :show-close="true"
      @close="close"
      width="50.3125vw"
      style="width: 100%"
    >
      <!-- 视频资源 -->
      <video
        class="w-p100 h-p100 video"
        :src="url"
        controls
        autoplay
        muted
        v-if="conentType == 'video'"
      ></video>

      <!-- 轮播图片 -->
      <div class="swiper-container" v-else-if="conentType == 'img'">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in imgs" :key="index">
            <img :src="item.fileUrl" alt="" class="w-p100 h-p100" />
          </div>
        </div>
        <div class="swiper-button-next">
          <img src="./img/2.png" class="icon" alt="" />
        </div>
        <div class="swiper-button-prev">
          <img src="./img/1.png" class="icon" alt="" />
        </div>
      </div>

      <!-- 关闭按钮 -->
      <!-- <img src="@" alt=""> -->
    </el-dialog>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "",
    },
    conentType: {
      type: String,
      default: "video",
    },
    imgs: {
      type: Array,
      default: () => [],
    },
    swiperIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogTableVisible: false,
    };
  },

  watch: {
    value: {
      handler: function (val) {
        this.dialogTableVisible = val;
      },
    },
    imgs: {
      handler: function (val) {
        if (val.length > 0 && this.conentType == "img") {
          this.$nextTick(() => {
            let mySwiper = new Swiper(".swiper-container", {
              autoplay: false, //可选选项，自动滑动
              loop: true,
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            });

            // 滚动到指定位置
            mySwiper.slideTo(this.swiperIndex);
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    close() {
      this.$emit("close", false);
      this.$emit("update:value", false);
    },
  },
};
</script>

<style>
.el-dialog__header {
  /* padding: 0 !important; */
  background: #000;
  padding-top: 1.3021vw !important;
}
.el-dialog__headerbtn {
  top: 0.5208vw !important;
}
.el-icon-close:before {
  color: #fff !important;
  font-size: 18px;
}
.el-dialog__body {
  padding: 0 !important;
  background: #000 !important;
}

.el-popup-parent--hidden {
  padding-right: 0 !important;
}
.swiper-button-prev {
  left: 2.0833vw;
}
.swiper-button-prev:after {
  color: #fff !important;
}
.swiper-button-next {
  right: 2.0833vw;
}
.swiper-button-next:after {
  color: #fff !important;
}
</style>

<style scoped lang="scss">
.video {
  object-fit: contain;
  width: 50.3125vw;
  background: #000;
  height: 32.0313vw;
}

.swiper-container {
  width: 50.3125vw;
  height: 32.0313vw;
}
.swiper-button-next,
.swiper-button-prev {
  .icon {
    width: 3.125vw;
    height: 3.125vw;
  }
}
</style>
