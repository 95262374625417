<template>
  <div
    class="submit-btn flex-center fs16 cp mb20 set_1_btn Vbtn-1"
    :class="submitBtnOver ? 'set_1_btn_bg' : ''"
    @click="submit"
    @mouseover="btnMouseOver"
    @mouseleave="btnMouseLeave"
  >
    <svg>
      <rect
        fill="none"
        width="100%"
        height="100%"
        :rx="rectRadius" :ry="rectRadius"
        :style="{
          '--dashoffset': styleObj.strokeDashoffset,
          '--strokedasharray': styleObj.strokeDasharray,
          '--storeWidth':storeWidth
        }"
      ></rect>
    </svg>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    styleObj: {
      type: Object,
      default: () => {
        return {
          strokeDashoffset: "176%",
          strokeDasharray:'65%,95%'
        };
      },
    },
    // 设置圆角
    rectRadius:{
      type:String,
      default:'0'
    },
    // 设置线条宽度
    storeWidth:{
      type:String,
      default:'.2604vw'
    }
  },
  data() {
    return {
      submitBtnOver: false,
      submitBtnTimer: null,
    };
  },
  methods: {
    // 按钮填充动画
    btnMouseOver() {
      if (this.submitBtnTimer) {
        clearTimeout(this.submitBtnTimer);
      }
      this.submitBtnTimer = setTimeout(() => {
        this.submitBtnOver = true;
      }, 400);
    },
    btnMouseLeave() {
      clearTimeout(this.submitBtnTimer);
      this.submitBtnOver = false;
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.set_1_btn {
  color: #1dff00;
  // background: red !important;
  cursor: pointer;
  font-weight: 400;
  height: 2.7083vw;
  // margin-right: 2em;
  text-align: center;
  // width: 26.1458vw;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
}

.set_1_btn:hover {
  text-decoration: none;
}

.set_1_btn_bg {
  color: #101010;
  transition: background-color 0.3s ease;
  background-color: #1dff00 !important;
}

.Vbtn-1 {
  background: transparent;
  text-align: center;
  float: left;
}

.Vbtn-1 svg {
  // height: 2.7083vw;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Vbtn-1 rect {
  fill: none;
  stroke: #1dff00;
  stroke-width: var(--storeWidth);
  stroke-dasharray: 422, 0;
  transition: all 0.8s linear 0s;
}

.Vbtn-1:hover {
  background: rgba(225, 51, 45, 0);
  // font-weight: 900;
  font-size: 1.0417vw;
  letter-spacing: 1px;
  transition: all 150ms linear 0s;
}

.Vbtn-1:hover rect {
  // stroke-width: .2604vw;
  stroke-dasharray: var(--strokedasharray);
  // stroke-dashoffset: 376%;
  stroke-dashoffset: var(--dashoffset);
  // stroke-dasharray: 5.6771vw, 23.3854vw;
  // stroke-dashoffset: 60.1042vw;
  -webkit-transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}
</style>
