
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: ()=>import('../views/index'),
    meta:{
      title:'首页'
    }
  },
  {
    path: '/life',
    name: 'life',
    component: ()=>import('../views/life'),
    meta:{
      title:'枪调生活'
    }
  },
  {
    path: '/booking',
    name: 'booking',
    component: ()=>import('../views/booking'),
    meta:{
      title:'试驾预约'
    }
  },
  {
    path: '/product/:name',
    name: 'product',
    component: ()=>import('../views/product'),
    meta:{
      title:'产品详情'
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: ()=>import('../views/shop'),
    meta:{
      title:'门店信息'
    }
  },
  {
    path: '/story',
    name: 'story',
    component: ()=>import('../views/story'),
    meta:{
      title:'故事'
    }
  },
  {
    path: '/video',
    name: 'video',
    component: ()=>import('../views/video'),
    meta:{
      title:'视频锦集'
    }
  },
  {
    path: '/warranty-policy',
    name: 'warranty-policy',
    component: ()=>import('../views/warranty-policy'),
    meta:{
      title:'保修政策'
    }
  },
  {
    path: '/lead-story',
    name: 'lead-story',
    component: ()=>import('../views/lead-story'),
    meta:{
      title:'Ak头条'
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: ()=>import('../views/maintenance'),
    meta:{
      title:'保养知识'
    }
  },
  {
    path: '/guarantee',
    name: 'guarantee',
    component: ()=>import('../views/guarantee'),
    meta:{
      title:'报修服务'
    }
  },
  {
    path: '/become',
    name: 'become',
    component: ()=>import('../views/become'),
    meta:{
      title:'Ak加盟'
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

// 设置标题
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    // document.title = to.meta.title
  }
  window.scrollTo(0, 0);
  next()
})


// router.afterEach((to,from,next)=>{
//   window.screenTop(0,0)
  
// })
export default router
