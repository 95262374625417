var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"nav"}},[_c('div',{staticClass:"left-nav h-p100"},[_c('img',{staticClass:"logo cp",attrs:{"src":require("@/assets/images/logo3.png"),"alt":""},on:{"click":function($event){return _vm.go('/')}}}),_c('div',{staticClass:"menu-wrapper pos-center flex-center cp",attrs:{"data-status":"0"},on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('img',{staticClass:"menu-icon",attrs:{"src":_vm.menuIcon,"alt":""}})])]),_c('div',{staticClass:"color-fff menu-box ",class:_vm.visible ? 'menu-box-open' : 'menu-box-close'},[_c('ul',{staticClass:"nav-header fs17 flex-align-center"},[_c('li',{staticClass:"nav-header-item cp",on:{"click":function($event){return _vm.go('/become')}}},[_c('Btn',{staticClass:"fs18 yuyue-btn",attrs:{"rectRadius":"0.5vw","storeWidth":"1.5px"}},[_vm._v("AK招商")])],1),_c('li',{staticClass:"nav-header-item cp"},[_c('a',{attrs:{"href":"https://mall.jd.com/index-13147891.html","target":"_blank"}},[_c('Btn',{staticClass:"fs18 yuyue-btn",attrs:{"rectRadius":"0.5vw","storeWidth":"1.5px"}},[_vm._v("AK商城")])],1)]),_c('li',{staticClass:" nav-header-item"},[_c('Btn',{staticClass:"fs18 yuyue-btn",attrs:{"rectRadius":"0.5vw","storeWidth":"1.5px"},on:{"submit":function($event){return _vm.go('/booking')}}},[_vm._v(" 预约试驾 ")])],1)]),_c('ul',{staticClass:"menu flex-align-center"},_vm._l((_vm.list),function(item,index){return _c('li',{key:index,staticClass:"item meun-item flex-center",style:({
          '--img': ("url('" + (item.backImg) + "')"),
          '--activeimg': ("url('" + (item.activeBackImg) + "')"),
        }),on:{"mouseenter":function($event){return _vm.mouseenter($event, index)},"mouseleave":function($event){return _vm.mouseleave($event, index)}}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img5 mb30 w46",attrs:{"src":item.childShow
            ? require('./img/6.png')
            : require('./img/_5.png'),"alt":""}}),_c('p',{staticClass:"fs24",on:{"click":function($event){$event.stopPropagation();return _vm.jump(item)}}},[_vm._v(" "+_vm._s(item.navigationName)+" ")]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(item.childList && item.childShow),expression:"item.childList && item.childShow"}],staticClass:"child-list animate__animated animate__fadeInDown"},_vm._l((item.childList),function(el,el_index){return _c('li',{key:el_index,staticClass:"child-item fs18",on:{"click":function($event){$event.stopPropagation();return _vm.jump(el)}}},[_vm._v(" "+_vm._s(el.navigationName)+" ")])}),0)])])}),0),_c('div',{staticClass:"nav-footer pl80 pr80"},[_c('div',{staticClass:"footer-box1 flex-align-center flex-space-between"},[_c('div',[_c('span',{staticClass:"mr35 fs14"},[_vm._v(" 联系我们 ")]),_c('span',{staticClass:"mr35 fs14"},[_vm._v(" 热线电话："+_vm._s(_vm.company.phone)+" ")]),_c('span',{staticClass:"fs14"},[_vm._v("地址："+_vm._s(_vm.company.address))])]),_c('div',{staticClass:"flex-align-center"},[_c('div',{staticClass:"fs14 color-fff mr23"},[_vm._v(_vm._s(_vm.company.companyName))]),_c('div',{staticClass:"cp flex-align-center icon-box"},[_c('div',[_c('div',{staticClass:"icon-item mr5",style:({
                '--img': ("url(" + (require('./img/17.png')) + ")"),
                '--acimg': ("url(" + (require('./img/17-1.png')) + ")"),
              })}),_c('div',{staticClass:"toptips pad10"},[_c('img',{staticClass:"imgurl",attrs:{"src":_vm.company.akImg,"alt":""}}),_c('div',{staticClass:"text-center fs16"},[_vm._v(" 扫码关注AK动态 ")])])]),_c('div',[_c('div',{staticClass:"icon-item mr5",style:({
                '--img': ("url(" + (require('./img/20.png')) + ")"),
                '--acimg': ("url(" + (require('./img/20-1.png')) + ")"),
              })}),_c('div',{staticClass:"toptips pad10"},[_c('img',{staticClass:"imgurl",attrs:{"src":_vm.company.xhsImg,"alt":""}}),_c('div',{staticClass:"text-center fs16"},[_vm._v(" 种草AK生活 ")])])]),_c('div',[_c('div',{staticClass:"icon-item mr5",style:({
                '--img': ("url(" + (require('./img/9.png')) + ")"),
                '--acimg': ("url(" + (require('./img/9-1.png')) + ")"),
              })}),_c('div',{staticClass:"toptips pad10"},[_c('img',{staticClass:"imgurl",attrs:{"src":_vm.company.wxImg,"alt":""}}),_c('div',{staticClass:"text-center fs16"},[_vm._v(" 找到Ak组织 ")])])]),_c('div',[_c('div',{staticClass:"icon-item mr5",style:({
                '--img': ("url(" + (require('./img/14.png')) + ")"),
                '--acimg': ("url(" + (require('./img/14-1.png')) + ")"),
              })}),_c('div',{staticClass:"toptips pad10"},[_c('img',{staticClass:"imgurl",attrs:{"src":_vm.company.dyImg,"alt":""}}),_c('div',{staticClass:"text-center fs16"},[_vm._v(" 记录AK时刻 ")])])]),_c('div',[_c('div',{staticClass:"icon-item mr5",style:({
                '--img': ("url(" + (require('./img/12.png')) + ")"),
                '--acimg': ("url(" + (require('./img/12-1.png')) + ")"),
              })}),_c('div',{staticClass:"toptips pad3"},[_c('img',{staticClass:"imgurl",attrs:{"src":_vm.company.xlImg,"alt":""}}),_c('div',{staticClass:"text-center fs16"},[_vm._v(" 聚焦AK热点 ")])])])])])]),_c('div',{staticClass:"fs14 footer-box2 flex-center",staticStyle:{"color":"#676b72"}},[_vm._v(" 渝ICP备 16002072号-2 渝公网安备 50011302000410号COPYRIGHT© akmoto ALL RIGHTS RESERVED ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }