<template>
  <div id="nav">
    <!-- 左侧导航 -->
    <div class="left-nav h-p100">
      <img src="@/assets/images/logo3.png" class="logo cp" alt="" @click="go('/')" />

      <!-- 菜单按钮 -->
      <div class="menu-wrapper pos-center flex-center cp" data-status="0" @click="visible = !visible">
        <img :src="menuIcon" class="menu-icon" alt="" />
      </div>
    </div>

    <!-- animate__fadeInLeft animate__animated   v-show="visible"-->
    <div class="color-fff menu-box " :class="visible ? 'menu-box-open' : 'menu-box-close'">
      <!-- 顶部导航 -->
      <ul class="nav-header fs17 flex-align-center">
        <li class="nav-header-item cp" @click="go('/become')">
          <!-- AK招商 -->
          <Btn rectRadius="0.5vw" storeWidth="1.5px" class="fs18 yuyue-btn">AK招商</Btn>
        </li>
        <li class="nav-header-item cp">
          <!-- <a href="https://mall.jd.com/index-13147891.html" class="color-fff" target="_blank"
            rel="noopener noreferrer">AK商城</a> -->
          <a href="https://mall.jd.com/index-13147891.html" target="_blank">
            <Btn rectRadius="0.5vw" storeWidth="1.5px" class="fs18 yuyue-btn">AK商城</Btn>
          </a>
        </li>
        <li class=" nav-header-item">
          <Btn rectRadius="0.5vw" storeWidth="1.5px" class="fs18 yuyue-btn" @submit="go('/booking')">
            预约试驾
          </Btn>
        </li>
      </ul>

      <!-- 菜单 -->
      <ul class="menu flex-align-center">
        <li class="item meun-item flex-center" v-for="(item, index) in list" :key="index"
          @mouseenter="mouseenter($event, index)" @mouseleave="mouseleave($event, index)" :style="{
            '--img': `url('${item.backImg}')`,
            '--activeimg': `url('${item.activeBackImg}')`,
          }">
          <div class="text-center">
            <img :src="item.childShow
              ? require('./img/6.png')
              : require('./img/_5.png')
              " class="img5 mb30 w46" alt="" />
            <p class="fs24" @click.stop="jump(item)">
              {{ item.navigationName }}
            </p>

            <ul class="child-list animate__animated animate__fadeInDown" v-show="item.childList && item.childShow">
              <li class="child-item fs18" v-for="(el, el_index) in item.childList" :key="el_index" @click.stop="jump(el)">
                {{ el.navigationName }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <!-- 收起按钮 -->
      <!-- <div class="menu-close pos-vertical-center flex-center fs16 cp" @click="visible = false">
        <img src="./img/19.png" class="w-p100 h-p100" alt="" />
      </div> -->

      <!-- 底部信息 -->
      <div class="nav-footer pl80 pr80">
        <div class="footer-box1 flex-align-center flex-space-between">
          <div>
            <span class="mr35 fs14"> 联系我们 </span>
            <span class="mr35 fs14"> 热线电话：{{ company.phone }} </span>
            <span class="fs14">地址：{{ company.address }}</span>
          </div>

          <div class="flex-align-center">
            <div class="fs14 color-fff mr23">{{ company.companyName }}</div>

            <div class="cp flex-align-center icon-box">
              <div>
                <div class="icon-item mr5" :style="{
                  '--img': `url(${require('./img/17.png')})`,
                  '--acimg': `url(${require('./img/17-1.png')})`,
                }"></div>
                <div class="toptips pad10">
                  <img :src="company.akImg" class="imgurl" alt="" />
                  <div class="text-center fs16">
                    扫码关注AK动态
                  </div>
                </div>
              </div>

              <div>
                <div class="icon-item mr5" :style="{
                  '--img': `url(${require('./img/20.png')})`,
                  '--acimg': `url(${require('./img/20-1.png')})`,
                }"></div>
                <div class="toptips pad10">
                  <img :src="company.xhsImg" class="imgurl" alt="" />
                  <div class="text-center fs16">
                    种草AK生活
                  </div>
                </div>
              </div>

              <div>
                <!-- <img src="./img/9.png" class="w30 mr5" alt="" /> -->
                <div class="icon-item mr5" :style="{
                  '--img': `url(${require('./img/9.png')})`,
                  '--acimg': `url(${require('./img/9-1.png')})`,
                }"></div>
                <div class="toptips pad10">
                  <img :src="company.wxImg" class="imgurl" alt="" />
                  <div class="text-center fs16">
                    找到Ak组织
                  </div>
                </div>
              </div>

              <div>
                <!-- <img src="./img/14.png" class="w30 mr5" alt="" /> -->
                <div class="icon-item mr5" :style="{
                  '--img': `url(${require('./img/14.png')})`,
                  '--acimg': `url(${require('./img/14-1.png')})`,
                }"></div>
                <div class="toptips pad10">
                  <img :src="company.dyImg" class="imgurl" alt="" />
                  <div class="text-center fs16">
                    记录AK时刻
                  </div>
                </div>
              </div>

              <div>
                <!-- <img src="./img/12.png" class="w30 mr5" alt="" /> -->
                <div class="icon-item mr5" :style="{
                  '--img': `url(${require('./img/12.png')})`,
                  '--acimg': `url(${require('./img/12-1.png')})`,
                }"></div>
                <div class="toptips pad3">
                  <img :src="company.xlImg" class="imgurl" alt="" />
                  <div class="text-center fs16">
                    聚焦AK热点
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="fs14 footer-box2 flex-center" style="color: #676b72">
          渝ICP备 16002072号-2 渝公网安备 50011302000410号COPYRIGHT© akmoto ALL RIGHTS RESERVED
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      company: {},
      visible: false,
    };
  },
  mounted() {
    this.getNav();
    this.getInfo();
  },
  computed: {
    menuIcon() {
      return this.visible ? require('@/assets/images/2.png') : require('@/assets/images/2-1.png')
    }
  },
  methods: {
    async getInfo() {
      const [err, res] = await this.$http.get("/api/param/query");
      if (err) return;
      this.company = res;
    },
    async getNav() {
      const [err, res] = await this.$http.get("api/navigation/query");
      if (err) return;
      for (const i of res) {
        i.childShow = false;
      }
      this.list = res;
    },
    jump(item) {
      if (item.childList && item.childList.length > 0) return;
      // if (this.$route.fullPath == item.jumpUrl) return (this.visible = false);
      this.$router.push(item.jumpUrl).catch((err) => { });
      this.visible = false;
    },

    goHome() {
      this.$router.replace("/");
    },
    mouseenter(e, index) {
      this.list[index].childShow = true;
    },
    mouseleave(e, index) {
      this.list[index].childShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.left-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 3.75vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.left-nav .logo {
  width: 2.1875vw;
  display: block;
  margin: 0 auto;
}

.left-nav .menu-wrapper {
  height: 100px;
  width: 100%;
}

.left-nav .menu-wrapper .menu-icon {
  width: 0.9375vw;
  height: 4.5833vw;
}

.menu-box-open {
  transform: translateX(0) !important;
}

.menu-box-close {

  transform: translateX(-100%);
}

.menu-box {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-image: url(./img/18.png);
  background-size: cover;
  // background: linear-gradient(145deg, #19202c 20%, rgba(52, 64, 83, 0.00) 72%, rgba(52, 64, 83, 0.00) 60%);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10001;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;

  .menu-close {
    width: 7.2917vw;
    height: 3.3333vw;
    background: #1dff00;
    right: 0;
    color: #06070a;
  }

  .nav-header {
    height: 3.6458vw;
    width: 100%;
    // background-color: #DDD;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 9999;
    position: relative;

    .nav-header-item {
      // min-width: 7.8125vw;
      margin-right: 6.3542vw;
      font-size: 1.0417vw;

      // &:nth-last-child(1) {
      //   width: auto;
      // }
    }

    .yuyue-btn {
      width: 6.9792vw !important;
      height: 1.7708vw !important;
      margin-bottom: 0 !important;
      border-radius: .4167vw;
    }

    .img7 {
      width: 4.875vw !important;
    }
  }

  .nav-footer {
    width: 100%;
    height: 5.7292vw;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #b6b6b6;
    z-index: 10002;

    .footer-box1 {
      height: 3.5417vw;
    }

    .footer-box2 {
      height: 2.5vw;
    }
  }

  .icon-box {
    .icon-item {
      width: 1.5625vw;
      height: 1.5625vw;
      background-image: var(--img);
      background-size: cover;

      &:hover {
        background-image: var(--acimg);
      }
    }

    >div {
      position: relative;
      margin-right: 1.5625vw;

      .toptips {
        width: 10.9375vw;
        // height: 11.9792vw;
        height: 13.9063vw;
        background-image: url(./img/8.png);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: 2.5625vw;
        left: -8.5375vw;
        display: none;
        text-align: center;
        padding: 0.3125vw !important;
        box-sizing: border-box;
        color: #06070a;

        >.imgurl {
          width: 10.0167vw;
          height: 9.9167vw;
          object-fit: cover;
          // position: absolute;
          // left: 50%;
          // transform: translateX(-50%);
        }
      }

      &:hover>.toptips {
        display: block;
      }
    }
  }

  .menu {
    width: 76.4063vw;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    left: 10.5729vw;
    top: 50%;
    transform: translateY(-50%);

    .item {
      .child-list {
        // line-height: 4.6237vh;
        // display: none;
        margin-top: 3.8542vw;

        .child-item {
          margin-bottom: 1.7708vw;

          &:hover {
            color: #1dff00;
          }
        }
      }

      width: 14.0625vw;
      height: 31.25vw;
      // background-image: url(./img/1.png);
      background-image: var(--img);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      position: relative;
      transition: border-color 0.3s ease;
      /* 添加边框的过渡效果 */

      &:hover {
        background-image: var(--activeimg);
      }

      // &:nth-child(2n) {
      //   background-image: url(./img/4.png);

      //   &:hover {
      //     background-image: url(./img/2.png);
      //   }
      // }
    }
  }
}
</style>
